/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Core
import { Schema, Validators } from '@common/validation';

// TODO: impoer each validator independently via spread
const field = new Validators();

import type { TDomainsFilters } from '@/server/services/Domains';

export const premiumFilters = ['minPrice', 'maxPrice', 'minAttractivity', 'maxAttractivity', 'brandability'];

export const filterPresets: {
    [presetName: string]: TDomainsFilters
} = {
    short: {
        minLength: 0,
        maxLength: 6
    },
    cheap: {
        minPrice: 0,
        maxPrice: 100
    },
    brandable: {
        brandability: 'high'
    },
    popular: {
        popularity: 'high'
    },
    positive: {
        emotion: 'positive'
    },
    hacks: {
        format: 'hack'
    },
    verbs: {
        type: 'verb'
    },
    nouns: {
        type: 'noun'
    },

    // Dynamic: Sector
}

// TODO: optimize
export const findPreset = (filters: TDomainsFilters) => Object.keys(filterPresets).find((presetName) => 
    (
        Object.keys(filters).filter(k => filters[k] !== undefined).sort().join() 
        === 
        Object.keys( filterPresets[presetName] ).sort().join()
    )
    &&
    Object.entries(filters).every(([key, value]) => filterPresets[presetName][key] === value)
)

/*----------------------------------
- SCHEMA
----------------------------------*/
export default new Schema({

    page: field.int({ opt: true }),

    keywords: field.string({ opt: true, max: 150 }),

    tld: field.string({ opt: true }),

    type: field.choice([
        { "label": "All", "value": undefined },
        { "label": "Noun", "value": "noun" },
        { "label": "Pnound", "value": "pnound" },
        { "label": "Adjective", "value": "adjective" },
        { "label": "Verb", "value": "verb" },
        { "label": "Adjective satellite", "value": "adjective satellite" },
        { "label": "Proverb", "value": "proverb" },
        { "label": "Contraction", "value": "contraction" },
        { "label": "Adverb", "value": "adverb" },
        { "label": "Letter", "value": "letter" },
        { "label": "Interjection", "value": "interjection" },
        { "label": "Pronoun", "value": "pronoun" },
        { "label": "Conjunction", "value": "conjunction" },
        { "label": "PrepPhrase", "value": "prepPhrase" },
        { "label": "Particle", "value": "particle" },
        { "label": "Symbol", "value": "symbol" },
        { "label": "Preposition", "value": "preposition" },
        { "label": "Determiner", "value": "determiner" },
    ], { opt: true }),

    sector: field.string({ opt: true }),

    popularity: field.choice([{
        label: 'High',
        value: 'high'
    }, {
        label: 'Medium',
        value: 'medium'
    }, {
        label: 'Low',
        value: 'low'
    }], { opt: true }),

    brandability: field.choice([{
        label: 'High',
        value: 'high'
    }, {
        label: 'Medium',
        value: 'medium'
    }, {
        label: 'Low',
        value: 'low'
    }], { opt: true }),

    emotion: field.choice([{
        label: 'Positive',
        value: 'positive'
    }, {
        label: 'Negative',
        value: 'negative'
    }, {
        label: 'Neutral',
        value: 'neutral'
    }], { opt: true }),

    market: field.choice([{
        label: 'Registration',
        value: 'register'
    }, {
        label: 'Premium',
        value: 'premium'
    }/*, {
        label: 'Auction',
        value: 'auction'
    }*/], { opt: true }),

    format: field.choice([{
        label: 'Domain Hack',
        value: 'hack'
    }], { opt: true }),

    minLength: field.int({ opt: true }),
    maxLength: field.int({ opt: true }),

    minPrice: field.float({ opt: true }),
    maxPrice: field.float({ opt: true }),

    minAttractivity: field.int({ opt: true }),
    maxAttractivity: field.int({ opt: true }),

});
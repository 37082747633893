/*----------------------------------
- DEPENDANCES
----------------------------------*/

// Npm
import React from 'react';
import type { ComponentChild } from 'preact';
import { useErrorBoundary } from 'preact/hooks';

// Core
import RouterComponent from '@client/services/router/components/router';
import { Button, Popover, Link, Input } from '@client/components';
import getPosition from '@client/components/containers/Popover/getPosition';
import { Props as TBtnProps } from '@client/components/button'
;import { deepContains } from '@client/utils/dom';
import useContext from '@/client/context';

// App
import { buildSearchUrl, getCurrentFilters} from '@/common/libs/filters';
import DomainFiltersForm from '@/common/forms/domains/filters';

// Resources
import sectorIcons from './sectorIcons';
import LogoDark from '@/client/assets/identity/logo-dark.jpg';
import type { TDomainsStats } from '@/server/services/Stats';

type TFilterName = 'price' | 'length' | 'industries' | 'extensions' | 'sentiment' | 'popularity' | 'brandability' | 'wordtype';

/*----------------------------------
- COMPONENT
----------------------------------*/

export default ({ stats, close }: { 
    stats: TDomainsStats,
    close: () => void
}) => {

    const context = useContext();
    const { page, Router } = context;

    const [openFilter, setOpenFilter] = React.useState<TFilterName>(null);
    const [filterPositions, setFilterPositions] = React.useState({});
    const currentFilters = getCurrentFilters(context);
    let filterColor = 1;
    const isListingPage = page.data.domains !== undefined;

    const toggleFilter = (menu: TFilterName) => setOpenFilter( openFilter === menu ? null : menu );

    const FilterOpenButton = ({ filter, ...btnProps }: {
        filter: string,
    } & TBtnProps) => <>

        <Button iconR="chevron-down" 
            onClick={() => toggleFilter( filter )} 
            active={openFilter === filter} 
            {...btnProps} />

        {currentFilters[filter] && (
            <ul class="Current row wrap">
                <li class={"badge bg light" + (filterColor++)}>
                    {currentFilters[filter]}
                </li>
            </ul>
        )}
    </>

    const FilterChangeButton = ({ filter, value, ...btnProps }: {
        filter: string,
        value: string,
    } & TBtnProps) => {
        return (
            <li>
                <Button
                    active={currentFilters[filter] === value}
                    link={buildSearchUrl({ [filter]: value, page: 1 }, context, true)}
                    onClick={() => setOpenFilter(null)}
                    children={btnProps.title}
                    {...btnProps}
                    target={undefined}
                    class="col-1"
                />
            </li>
        )
    }

    return (
        <nav class="col Filters">

            <header class="row sp-btw mobile">
                <h3>
                    Filter domains
                </h3>
                <Button icon="times" onClick={() => close()} />
            </header>


            <ul class="col menu">
                <li>
                    <FilterOpenButton filter="price" icon="dollar-sign">
                        Price
                    </FilterOpenButton>
                    <ul id="priceFilter" class="col menu scrollable w-3" style={filterPositions.price}>
                        <li>
                            <Button onClick={() => Router.go(buildSearchUrl({
                                maxPrice: 30
                            }, context))}>Under 30$</Button>
                        </li>
                        <li>
                            <Button onClick={() => Router.go(buildSearchUrl({
                                minPrice: 30, maxPrice: 100
                            }, context))}>30-100$</Button>
                        </li>
                        <li>
                            <Button onClick={() => Router.go(buildSearchUrl({
                                minPrice: 100, maxPrice: 500
                            }, context))}>100-500$</Button>
                        </li>
                        <li>
                            <Button onClick={() => Router.go(buildSearchUrl({
                                minPrice: 500
                            }, context))}>500+</Button>
                        </li>
                    </ul>
                </li>
                <li>
                    <FilterOpenButton filter="length" icon="arrows-alt-h">
                        Length
                    </FilterOpenButton>
                    <ul id="lengthFilter" class="col menu scrollable w-3" style={filterPositions.length}>
                        <li>
                            <Button onClick={() => Router.go( buildSearchUrl({
                                minLength: 1, maxLength: 5
                            }, context))}>Short (1-5 characters)</Button>
                        </li>
                        <li>
                            <Button onClick={() => Router.go( buildSearchUrl({
                                minLength: 6, maxLength: 9
                            }, context))}>Medium (6-9 characters)</Button>
                        </li>
                        <li>
                            <Button onClick={() => Router.go( buildSearchUrl({
                                minLength: 10, maxLength: 15
                            }, context))}>Long (10-15 characters)</Button>
                        </li>
                    </ul>
                </li>
                {/* <li>
                    <FilterOpenButton filter="sector" icon="industry">
                        Industries
                    </FilterOpenButton>
                    <ul id="industryFilter" class="col menu scrollable" style={filterPositions.industry}>
                        {stats.sectors.map( sector => (
                            <FilterChangeButton filter="sector" value={sector.slug}
                                title={sector.name}
                                icon={sectorIcons[ sector.slug ]}
                            />
                        ))}
                    </ul>
                </li> */}
                <li>
                    <FilterOpenButton filter="tld" icon="globe">
                        Extensions
                    </FilterOpenButton>
                    <ul id="tldFilter" class="menu col scrollable" style={filterPositions.tld}>
                        {stats.tlds.map( tld => (
                            <FilterChangeButton filter="tld" value={tld}
                                title={'.' + tld}
                            />
                        ))}
                    </ul> 
                </li>
                <li>
                    <FilterOpenButton filter="emotion" icon="thumbs-up">
                        Sentiment
                    </FilterOpenButton>
                    <ul id="emotionFilter" class={"menu col w-2"} style={filterPositions.emotion}>
                        {DomainFiltersForm.fields.emotion.componentAttributes.choices.map(choice => (
                            <FilterChangeButton filter="emotion" value={choice.value} title={choice.label} />
                        ))}
                    </ul>
                </li>
                <li>
                    <FilterOpenButton filter="popularity" icon="star">
                        Popularity
                    </FilterOpenButton>
                    <ul id="popularityFilter" class={"menu col w-2"} style={filterPositions.popularity}>
                        {DomainFiltersForm.fields.popularity.componentAttributes.choices.map(choice => (
                            <FilterChangeButton filter="popularity" value={choice.value} title={choice.label} />
                        ))}
                    </ul>
                </li>
                <li>
                    <FilterOpenButton filter="brandability" icon="registered">
                        Brandability
                    </FilterOpenButton>
                    <ul id="brandabilityFilter" class={"menu col w-2"} style={filterPositions.brandability}>
                        {DomainFiltersForm.fields.brandability.componentAttributes.choices.map(choice => (
                            <FilterChangeButton filter="brandability" value={choice.value} title={choice.label} />
                        ))}
                    </ul>
                </li>
                {/* <li>
                    <FilterOpenButton filter="wordType" icon="font">
                        Word type
                    </FilterOpenButton>
                    <ul id="wordTypeFilter" class={"menu col h-4 w-2 scrollable"} style={filterPositions.wordType}>
                        {DomainFiltersForm.fields.type.componentAttributes.choices.map(choice => (
                            <FilterChangeButton filter="type" value={choice.value} title={choice.label} />
                        ))}
                    </ul>
                </li> */}
            </ul>
        </nav>
    )
}